import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Error from '../components/Error.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: "/index",
    meta: { title: "首页-高考桥" },

    children: [
      {
        path: "/index",
        name: 'index',
        component: () => import("../views/home/home.vue"),
        meta: { title: "首页-高考桥" },
      },
      {
        path: '/course',
        name: 'course',
        component: () => import('../views/home/course.vue'),
        meta: { title: "课程中心-高考桥", mustLog: true },
      },
      {
        path: '/coursePlayer',
        name: 'coursePlayer',
        component: () => import('../views/course/coursePlayer.vue'),
        meta: { title: "观看课程-高考桥", mustLog: true },
      },
      {
        path: '/courseDetail',
        name: 'courseDetail',
        component: () => import('../views/home/courseDetail.vue'),
        meta: { title: "课程详情-高考桥", mustLog: true },
      },
      {
        path: '/znlx',
        name: 'znlx',
        component: () => import('../views/home/znlx.vue'),
        meta: { title: "智能练习-高考桥", mustLog: true },
      },
      {
        path: '/xkgh',
        name: 'xkgh',
        component: () => import('../views/plan/planning.vue'),
        meta: { title: "选科规划-高考桥", mustLog: true },
      },
      {
        path: '/methodList',
        name: 'methodList',
        component: () => import('../views/plan/methodList.vue'),
        meta: { title: "选科测评-高考桥", mustLog: true },
      },
      {
        path: '/zytb',
        name: 'zytb',
        component: () => import('../views/home/zytb.vue'),
        meta: { title: "志愿填报-高考桥", mustLog: true },
      },
      {
        path: '/gkjyz',
        name: 'gkjyz',
        component: () => import('../views/home/gkjyz.vue'),
        meta: { title: "高考加油站-高考桥", mustLog: true },
      },
      {
        path: '/zxwd',
        name: 'zxwd',
        component: () => import('../views/home/zxwd.vue'),
        meta: { title: "在线问答-高考桥", mustLog: true },
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/public/privacy.vue'),
        meta: { title: "隐私政策-高考桥" },
      },
      {
        path: '/service',
        name: 'service',
        component: () => import('../views/public/service.vue'),
        meta: { title: "服务协议-高考桥" },
      },
      {
        path: "/course_tow",
        name: 'course_tow',
        component: () => import("../views/course/course_tow.vue"),
        meta: { title: "专题课程-高考桥", mustLog: true },
      },
      {
        path: "/course_one",
        name: 'course_one',
        component: () => import("../views/course/course_one.vue"),
        meta: { title: "新课标课程-高考桥", mustLog: true },
      },
      {
        path: "/course_index",
        name: 'course_index',
        component: () => import("../views/course/course_index.vue"),
        meta: { title: "课程中心-高考桥", mustLog: true },
      },
      {
        path: "/fxbk",
        name: 'fxbk',
        component: () => import("../views/course/fxbk.vue"),
        meta: { title: "学习记录-高考桥", mustLog: true },
      },
      {
        path: "/jqjh",
        name: 'jqjh',
        component: () => import("../views/course/jqjh.vue"),
        meta: { title: "假期计划-高考桥", mustLog: true },
      },
      {
        path: "/zsdzd",
        name: 'zsdzd',
        component: () => import("../views/practice/zsdzd.vue"),
        meta: { title: "知识点诊断-高考桥", mustLog: true },
      },
      {
        path: "/zxst",
        name: 'zxst',
        component: () => import("../views/practice/onlineQuestion.vue"),
        meta: { title: "在线刷题-高考桥", mustLog: true },
      },
      {
        path: "/stlx",
        name: 'stlx',
        component: () => import("../views/practice/classRoom.vue"),
        meta: { title: "随堂练习-高考桥", mustLog: true },
      },
      {
        path: "/lnzt",
        name: 'lnzt',
        component: () => import("../views/practice/historical.vue"),
        meta: { title: "历年真题-高考桥", mustLog: true },
      },
      {
        path: "/jpsj",
        name: 'jpsj',
        component: () => import("../views/practice/quality.vue"),
        meta: { title: "精品试卷-高考桥", mustLog: true },
      },
      {
        path: "/dotrain",
        name: 'dotrain',
        component: () => import("../views/practice/dotrain.vue"),
        meta: { title: "知识点诊断-高考桥", mustLog: true },
      },
      {
        path: "/about",
        name: 'about',
        component: () => import("../views/home/about.vue"),
        meta: { title: "关于我们-高考桥", mustLog: true  },
      },
      {
        path: "/question",
        name: 'question',
        component: () => import("../views/plan/question.vue"),
        meta: { title: "选科测评-高考桥" , mustLog: true },
      },
      {
        path: "/result",
        name: 'result',
        component: () => import("../views/plan/result.vue"),
        meta: { title: "测评结果-高考桥" , mustLog: true },
      },
      {
        path: "/analysis",
        name: 'analysis',
        component: () => import("../views/plan/analysis.vue"),
        meta: { title: "学科分析-高考桥" , mustLog: true },
      },
      {
        path: "/choose",
        name: 'choose',
        component: () => import("../views/plan/choose.vue"),
        meta: { title: "选科查询-高考桥" , mustLog: true },
      },
      {
        path: "/subjectAnalysisTest",
        name: 'subjectAnalysisTest',
        component: () => import("../views/plan/subjectAnalysisTest.vue"),
        meta: { title: "学科兴趣分析-高考桥" , mustLog: true },
      },
      {
        path: "/chooseReport",
        name: 'chooseReport',
        component: () => import("../views/plan/chooseReport.vue"),
        meta: { title: "学科兴趣分析结果-高考桥" , mustLog: true },
      },
      {
        path: "/uploadscore",
        name: 'uploadscore',
        component: () => import("../views/plan/uploadscore.vue"),
        meta: { title: "上传平时成绩-高考桥" , mustLog: true },
      },
      {
        path: "/scoreReport",
        name: 'scoreReport',
        component: () => import("../views/plan/scoreReport.vue"),
        meta: { title: "学科成绩分析结果-高考桥" , mustLog: true },
      },
      {
        path: "/major",
        name: 'major',
        component: () => import("../views/plan/major.vue"),
        meta: { title: "专业库-高考桥" , mustLog: true },
      },
      {
        path: "/majorDetail",
        name: 'majorDetail',
        component: () => import("../views/plan/majorDetail.vue"),
        meta: { title: "专业详情-高考桥" , mustLog: true },
      },
      {
        path: "/career",
        name: 'career',
        component: () => import("../views/plan/career.vue"),
        meta: { title: "职业库-高考桥" , mustLog: true },
      },
      {
        path: "/careerDetail",
        name: 'careerDetail',
        component: () => import("../views/plan/careerDetail.vue"),
        meta: { title: "职业详情-高考桥" , mustLog: true },
      },
      {
        path: "/careerHotDetail",
        name: 'careerHotDetail',
        component: () => import("../views/plan/careerHotDetail.vue"),
        meta: { title: "热门职业详情-高考桥" , mustLog: true },
      },
      {
        path: "/college",
        name: 'college',
        component: () => import("../views/plan/college.vue"),
        meta: { title: "院校库-高考桥" , mustLog: true },
      },
      {
        path: "/collegeDetail",
        name: 'collegeDetail',
        component: () => import("../views/plan/collegeDetail.vue"),
        meta: { title: "院校详情-高考桥" , mustLog: true },
      },
      {
        path: "/admissionsGuide",
        name: 'admissionsGuide',
        component: () => import("../views/plan/admissionsGuide.vue"),
        meta: { title: "招生简章详情-高考桥" , mustLog: true },
      },
      {
        path: "/Volunteer",
        name: 'Volunteer',
        component: () => import("../views/plan/Volunteer.vue"),
        meta: { title: "志愿表-高考桥" , mustLog: true },
      },
      {
        path: "/volDetail",
        name: 'volDetail',
        component: () => import("../views/plan/volDetail.vue"),
        meta: { title: "志愿表详情-高考桥" , mustLog: true },
      },
      {
        path: '/userInfo',
        name: 'userInfo',
        component: () => import('../views/user/userInfo.vue'),
        redirect: "/baseInfo",
        meta: { title: "个人资料-高考桥", mustLog: true },
        children: [
          {
            path: "/baseInfo",
            name: 'baseInfo',
            component: () => import("../views/user/baseInfo.vue"),
            meta: { title: "个人资料-高考桥", mustLog: true },
          },
          {
            path: "/userzsdzd",
            name: 'userzsdzd',
            component: () => import("../views/user/userzsdzd.vue"),
            meta: { title: "知识点诊断-高考桥", mustLog: true },
          },
          {
            path: "/wrongbook",
            name: 'wrongbook',
            component: () => import("../views/user/wrongbook.vue"),
            meta: { title: "错题本-高考桥", mustLog: true },
          },
          {
            path: "/educard",
            component: () => import("../views/user/educard.vue"),
            meta: { title: "学习卡-高考桥", mustLog: true },
          },
          {
            path: "/letter",
            component: () => import("../views/user/letter.vue"),
            meta: { title: "消息中心-高考桥", mustLog: true },
          },
          {
            path: "/myfav",
            component: () => import("../views/user/myfav.vue"),
            meta: { title: "收藏夹-高考桥", mustLog: true },
          },
          {
            path: "/mywish",
            component: () => import("../views/user/mywish.vue"),
            meta: { title: "志愿表-高考桥", mustLog: true },
          },
          {
            path: "/safty",
            component: () => import("../views/user/safty.vue"),
            meta: { title: "账号安全-高考桥", mustLog: true },
          },
          {
            path: "/tasks",
            component: () => import("../views/user/tasks.vue"),
            meta: { title: "在线作业-高考桥", mustLog: true },
          },
          {
            path: "/testreport",
            component: () => import("../views/user/testreport.vue"),
            meta: { title: "测评报告-高考桥", mustLog: true },
          },
          {
            path: "/trainchart",
            component: () => import("../views/user/trainchart.vue"),
            meta: { title: "学习记录-高考桥", mustLog: true },
          },
        ]
      },
    ]
  },
  {
    path: '*',
    name: '404',
    component: Error
  },
  {
    path: "/search",
    name: 'search',
    component: () => import("../views/course/search.vue"),
    meta: { title: "搜索结果-高考桥" },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/public/login.vue'),
    meta: { title: "登录-高考桥" },
  },
  {
    path: '/allCategories',
    name: 'allCategories',
    component: () => import('../views/allCategories.vue'),
    meta: { title: "全部分类-高考桥", mustLog: true },
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('../views/public/forget.vue'),
    meta: { title: "忘记密码-高考桥" },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/public/register.vue'),
    meta: { title: "在线注册-高考桥" },
  },
  {
    path: '/onnumber',
    name: 'onnumber',
    component: () => import('../views/public/onnumber.vue'),
    meta: { title: "会员激活-高考桥" },
  },
  {
    path: '/xlfm',
    name: 'xlfm',
    component: () => import('../views/home/xlfm.vue'),
    meta: { title: "心灵FM-高考桥", mustLog: true },
  },
  {
    path: '/appdownload',
    name: 'appdownload',
    component: () => import('../views/home/appdownload.vue'),
    meta: { title: "下载-高考桥" },
  },
]


const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// 路由导航守卫
router.beforeEach((to, from, next) => {
  var sUserAgent = navigator.userAgent.toLowerCase();
  var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
  var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
  var bIsMidp = sUserAgent.match(/midp/i) == "midp";
  var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
  var bIsAndroid = sUserAgent.match(/android/i) == "android";
  var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
  var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
  if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
    window.location.replace('https://www.gkq211.com/h5/index.html')
  } else {
    

  }
  // let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  // if (flag) {
  //   window.open('https://www.gkq211.com/h5/index.html')
  // }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.path === "/login") {
    // window.localStorage.removeItem('schools');
    next()
    return;
  };
  if (to.meta.mustLog) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const tokenStr = window.sessionStorage.getItem("token");
    if (!tokenStr & to.path !== "/") return next("/login");
    if (!userInfo.card_number || !userInfo.hasTime) return next("/onnumber");
  }

  next();
});

export default router
